import React, { Fragment } from "react";
import KeepExploring from "./sections/KeepExploring";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TestArea from "./sections/Testing";

function App() {
  return (
    <Fragment>
      <Header />
      <TestArea />
      <KeepExploring />
      <Footer />
    </Fragment>
  );
}

export default App;

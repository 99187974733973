import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import ToolTip from "../ToolTip";

const BarWithAverage = ({ lowValue, average, highValue, activeValues }) => {
  const scale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className={s.container}>
      <ToolTip value={average} isAverage />
      <ToolTip value={lowValue} display={activeValues.negative} />
      <ToolTip value={highValue} display={activeValues.positive} />
      <div className={s.barContainer}>
        <div
          className={cn(s.negativeBar, { [s.active]: activeValues.negative })}
          style={{
            left: `${(lowValue / 10) * 100}%`,
            width: `${(average / 10) * 100 - (lowValue / 10) * 100}%`
          }}
        />
        <div
          className={cn(s.positiveBar, { [s.active]: activeValues.positive })}
          style={{
            left: `${(average / 10) * 100}%`,
            width: `${(highValue / 10) * 100 - (average / 10) * 100}%`
          }}
        />
        <div
          className={s.average}
          style={{ left: `${(average / 10) * 100}%` }}
        />
      </div>
      <div className={s.scaleContainer}>
        {scale.map(value => (
          <span key={value} className={s.scale}>
            {value}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BarWithAverage;

import React from "react";
import Rating from "../Rating";

const RatingGame = props => {
  const data = [
    {
      positive: { value: 8.43, label: "Can be trusted" },
      negative: { value: 3.43, label: "Can't be trusted" }
    },
    {
      positive: { value: 7.21, label: "Shows appreciation for hard work" },
      negative: {
        value: 3.69,
        label: "Doesn't show appreciation for hard work"
      }
    },
    {
      positive: {
        value: 7.89,
        label: "Can be approached with a personal problem"
      },
      negative: {
        value: 4.06,
        label: "Can't be approached with a personal problem"
      }
    },
    {
      positive: {
        value: 7.43,
        label: "Can be approached with a work problem"
      },
      negative: {
        value: 3.35,
        label: "Can't be approached with a work problem"
      }
    }
  ];
  return (
    <div>
      <br />
      {data.map(({ label, positive, negative }, index) => (
        <Rating
          key={index}
          label={label}
          positive={positive}
          negative={negative}
        />
      ))}
      <br />
      <br />
      <br />
    </div>
  );
};

export default RatingGame;

import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const ToolTip = ({ value, display = true, isAverage }) => {
  return (
    <div
      className={cn(
        s.container,
        { [s.display]: display },
        { [s.isAverage]: isAverage }
      )}
      style={{ left: `${(value / 10) * 100}%` }}
    >
      {value}
    </div>
  );
};

export default ToolTip;

import React from "react";
import Container from "../components/Container";
import RatingGame from "../components/RatingGame";

const TestArea = props => {
  return (
    <Container>
      <div>
        <br />
        <h2>Rating game</h2>
        <br />
        <p>
          So, what are the secrets to building a better relationship with
          reports?
        </p>
        <p>
          See what happens to manager ratings when they get these things right -
          and when they get it wrong.
        </p>
        <p>Say you're an average manager with an average rating.</p>
        <h5>4.73</h5>
        <p>Here's what happens to your score if you're a manager who...</p>
        <RatingGame />
        <br />
        <br />
        <br />
      </div>
    </Container>
  );
};

export default TestArea;

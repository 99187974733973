import React, { useState } from "react";
import BarWithAverage from "../BarWithAverage";
import s from "./style.module.css";
import cn from "classnames";

const Rating = ({ label, positive, negative }) => {
  const [activeValues, setActiveValues] = useState({
    positive: false,
    negative: false
  });

  const handleSwitch = e => {
    e.preventDefault();
    const active = activeValues;
    active[e.target.value] = !active[e.target.value];
    setActiveValues({ ...activeValues, ...active });
  };

  return (
    <div>
      <div className={s.controlsContainer}>
        <button
          className={cn(s.button, s.negativeButton)}
          onClick={e => handleSwitch(e)}
          value={"negative"}
        >
          {negative.label}
        </button>
        <button
          className={cn(s.button, s.positiveButton)}
          onClick={e => handleSwitch(e)}
          value={"positive"}
        >
          {positive.label}
        </button>
      </div>
      <BarWithAverage
        lowValue={negative.value}
        average={4.73}
        highValue={positive.value}
        activeValues={activeValues}
      />
      <br />
    </div>
  );
};

export default Rating;
